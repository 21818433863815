import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { STANDARD_EDITION, PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';

export const getDemonstratingTheValueOfJiraFeaturesCohort = ({
	edition,
	productKey,
	inGracePeriod,
}: {
	edition: string;
	productKey: string;
	inGracePeriod: boolean;
}): string => {
	if (!fg('demonstrating_value_of_jira_premium_features_gate')) {
		return 'not-enrolled';
	}

	const isSupportedStandard = edition === STANDARD_EDITION;
	const isSupportedPremium =
		edition === PREMIUM_EDITION &&
		(productKey === 'jira' || productKey === 'jira-core' || productKey === 'jira-software');
	const isSupportedEdition = isSupportedStandard || isSupportedPremium;
	const isEligible = isSupportedEdition && !inGracePeriod;

	if (!isEligible) {
		return 'not-enrolled';
	}

	// This should always be either `not-enrolled` or `control` as variant should
	// not be running this code
	return expVal('demonstrating_the_value_of_jira_premium_features', 'cohort', 'not-enrolled');
};
